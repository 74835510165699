/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@forward '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@forward '@ionic/angular/css/normalize.css';
@forward '@ionic/angular/css/structure.css';
@forward '@ionic/angular/css/typography.css';
@forward '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@forward '@ionic/angular/css/padding.css';
@forward '@ionic/angular/css/float-elements.css';
@forward '@ionic/angular/css/text-alignment.css';
@forward '@ionic/angular/css/text-transformation.css';
@forward '@ionic/angular/css/flex-utils.css';

ion-button.circle-icon {
  /* To make the button circle */
  --padding-start: 5.4px;
  --padding-end: var(--padding-start);

  &[size='small'] {
    --padding-start: 1.95px;
  }
}
