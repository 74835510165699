@use '@angular/material' as mat;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$app-accent: mat.m2-define-palette(mat.$m2-pink-palette);
$app-warn: mat.m2-define-palette(mat.$m2-red-palette);
$app-typography: mat.m2-define-typography-config(
  $font-family: 'Roboto,Helvetica Neue,sans-serif',
);

// Create the theme object (a Sass map containing all of the palettes).
$app-color: (
  primary: mat.m2-define-palette(mat.$m2-deep-purple-palette, $default: 700),
  accent: $app-accent,
  warn: $app-warn,
);
$app-theme: mat.m2-define-light-theme(
  (
    color: $app-color,
  )
);
$app-dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: mat.m2-define-palette(mat.$m2-deep-purple-palette, $default: 400),
      accent: $app-accent,
      warn: $app-warn,
    ),
  )
);

@include mat.core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@mixin theme-components($theme) {
  @include mat.core-color($theme);

  @include mat.tree-color($theme);
  @include mat.tooltip-color($theme);
  @include mat.menu-color($theme);
  @include mat.tabs-color($theme);
}

// Include typography and density styles once, for light and dark modes
@include mat.all-component-typographies($app-theme);
@include mat.all-component-densities($app-theme);

@include theme-components($app-theme);

@media (prefers-color-scheme: dark) {
  @include theme-components($app-dark-theme);
}

mat-tab-group {
  --mat-tab-header-divider-height: 0;
}
